import { ReactNode } from 'react';

import Flex from '../../common/Flex/Flex.tsx';
import Text from '../../common/Text/Text.tsx';

import styles from './EmptyPlaceholder.module.css';

type Props = {
  placeholderIcon: ReactNode;
  placeholderHeading?: string;
  children: ReactNode;
  height?: string;
  placeholderButton?: ReactNode;
};

function EmptyPlaceholder({
  placeholderIcon,
  placeholderHeading,
  children,
  height = '576px',
  placeholderButton,
}: Props) {
  return (
    <Flex justifyContent="center" alignItems="center" height={height} width="100%">
      <Flex flexDirection="column" justifyContent="center" alignItems="center" rowGap="12px">
        {placeholderIcon}
        {placeholderHeading && (
          <Text variant="Heading" size="Large" type="Medium" className={styles.EmptyMeetingHeading}>
            {placeholderHeading}
          </Text>
        )}

        <Text variant="Body" size="Midsize" type="Regular" className={styles.EmptyMeetingInfo}>
          {children}
        </Text>
        {placeholderButton && placeholderButton}
      </Flex>
    </Flex>
  );
}

export default EmptyPlaceholder;
